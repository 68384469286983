import React from 'react'
import './App.scss'
import data from './data.json'

class Component extends React.Component {

    state = {
        numberOfSongs: 2,
        isLoading: true,
        songs: [],
        randomNumbers: [],
        result: []
    }

    /*
    shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5)
    }

    randomNumbers = () => {
        var result = Array.from(Array(SONGS.length).keys())
        result = this.shuffleArray(result)
        return result.slice(0, this.state.numberOfSongs)
    }

    nextDecision = (i) => {
        var result = this.state.result 
        result.push(i)

        const randomNumbers = this.randomNumbers()
        this.setState({
            isLoading: false,
            randomNumbers,
            result
        })
    } 
    
    componentDidMount(){
        this.nextDecision()
    }
    */

    componentDidMount(){
        this.setState({
            isLoading: false,
            songs: data.songs
        })
    }

    render(){
        if (this.state.isLoading) return <div> Loading... </div>

        return <div id="main">
            <div className="intro">
                <div className="wrapper">
                    <h1> Reduce noise.</h1>
                    <h3> album cover edition</h3>
                </div>
            </div>
            <div className="tiles"> 
            {
                this.state.songs.map((song, i) => {
                    return <div key={i} className={`container`} style={{backgroundColor: song.background}}>
                        <h2 style={{
                            color: song.title.color
                        }}> {song.title.text} </h2>
                        <h4 style={{
                            color: song.artist.color
                        }}> {song.artist.text} </h4>

                        <a onClick={() => console.log(1)} href={() => false}>
                            <img alt="test" src={song.img} />
                        </a>
                    </div>
                })  
            }

            <div className="container dynamic" style={{backgroundColor: ""}}>
                <h2 style={{
                    color: "#C77B2B"
                }}> Capitol </h2>
                <h4 style={{
                    color: "#66462D"
                }}> 2Chainz </h4>
                <div className="figure">
                    <div className='f1'></div>
                    <div className='f2'></div>
                    <div className='f3'></div>
                    <div className='f4'></div>
                    <div className='f5'></div>
                    <div className='f6'></div>
                </div>
            </div>
        </div>
        </div>
    }
}

export default Component